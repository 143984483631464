import Utility from '../../../shared/utility.js';
import EncryptUtility from './encrypt-utility.js';
import AddFavourite from "./addFav.js";
import breadcrumbComp from '../../common/breadcrumb-comp';
import PrinterDetails from '../../common/printer-details';
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList:[],
      mainPage:"",
      subPage:"",
      pageUrl:"",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      primaryName:EncryptUtility.localStorageDecrypt('primaryName'),
      reprintLPNValid: false,
      lpnNum: '',
      primaryLabelSize: '',
      primaryPrinterType: '',
      showPrimary: true,
      max50Rule: [
        (v) => !!v || 'Field is required',
        (v) => (v || '').length <= 50 || "Max Length of 50 character"        
      ],
      reprintLPNRule: [
        (v) => !!v || 'Field is required',
        (value) => /^1-\d{3}(-\d{3})?$/.test(value) || 'Enter a correct format: X-XXX or X-XXX-XXX'
      ]
    };
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
  },
  methods: {
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd,this.userId,this.route)  
    },
    //Reset Function
    resetFunction() {
      this.reprintLPNValid = false;
      this.lpnNum = '';
      this.$refs.ReprintLPNForm.resetValidation();
    },
    //Reprint LPN Label based on LPN
    printLPN(){
      if (!this.$refs.ReprintLPNForm.validate()) return false;
      if(EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined){
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if(EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined){
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let printLPNLabel = {
        UserId: parseInt(this.userId),
        lpn: this.lpnNum,
      };
      this.axios
        .post('/ut/lpnlabel_reprint', printLPNLabel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            responsedata.Label.forEach((element) => {
              PrinterDetails.printLabelData(element.label,this.primaryName);
            });
            this.resetFunction();
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    // formatting number
      formatNumber(evt) {
        // Remove any non-numeric characters except for the initial digit
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (( charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 45 ) {
          evt.preventDefault();
        }
        else{
        let numbers = this.lpnNum.replace(/[^0-9]/g, '');
        
        if (numbers.length === 0) {
          this.lpnNum = '';
          return;
        }
        // Extract the initial digit(s) as the prefix
        let prefix = numbers[0];
        numbers = numbers.slice(1);
  
        // Format the remaining digits
        if (numbers.length <= 3) {
          this.lpnNum = `${prefix}-${numbers}`;
        } else {
          let formatted = `${prefix}-${numbers.slice(0, 3)}`;
          for (let i = 3; i < numbers.length; i += 3) {
            formatted += `-${numbers.slice(i, i + 3)}`;
          }
          this.lpnNum = formatted;
        }}
      },

  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
